/// Slightly lighten a color
/// @access public
/// @param {Color} $color - color to tint
/// @param {Number} $percentage - percentage of `$color` in returned color
/// @return {Color}
@function tint($color, $percentage) {
    @return mix(white, $color, $percentage * 1%);
}

/// Slightly darken a color
/// @access public
/// @param {Color} $color - color to shade
/// @param {Number} $percentage - percentage of `$color` in returned color
/// @return {Color}
@function shade($color, $percentage) {
    @return mix(black, $color, $percentage * 1%);
}

// Placeholders colors
@mixin optional-at-root($sel) {
    @at-root #{if(not &, $sel, selector-append(&, $sel))} {
        @content;
    }
}

@mixin placeholder {
    @include optional-at-root("::-webkit-input-placeholder") {
        @content;
    }

    @include optional-at-root(":-moz-placeholder") {
        @content;
    }

    @include optional-at-root("::-moz-placeholder") {
        @content;
    }

    @include optional-at-root(":-ms-input-placeholder") {
        @content;
    }
}

@mixin transform($string) {
    -webkit-transform: $string;
    -moz-transform: $string;
    -ms-transform: $string;
    -o-transform: $string;
}

@mixin animation($name, $duration, $iteration-count, $ease: ease) {
    -webkit-animation: $name $duration $iteration-count $ease;
    -moz-animation: $name $duration $iteration-count $ease;
    -ms-animation: $name $duration $iteration-count $ease;
}
