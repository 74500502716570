// Adding transition
a {
    transition: all 0.3s linear;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    text-decoration: none;
    &:hover {
        text-decoration: none;
    }
}

body {
    color: $default-text-color;
    background-color: $background-color;
    font-family: $font-family;
}
