// .container {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     // height: 100%;
// }

// .video-wrapper {
//     width: 100%;
//     max-width: 700px;
//     position: relative;
//     display: flex;
//     justify-content: center;
//     overflow: hidden;
//     // border-radius: 10px;
// }

// .video-wrapper:hover .controls {
//     transform: translateY(0%);
// }

// .controls {
//     display: flex;
//     align-items: center;
//     justify-content: space-evenly;
//     position: absolute;
//     bottom: 30px;
//     padding: 14px;
//     width: 100%;
//     max-width: 500px;
//     flex-wrap: inherit;
//     background: rgba(255, 255, 255, 0.25);
//     box-shadow: 0 8px 32px 0 rgba(255, 255, 255, 0.1);
//     backdrop-filter: blur(4px);
//     -webkit-backdrop-filter: blur(4px);
//     border-radius: 10px;
//     border: 1px solid rgba(255, 255, 255, 0.18);
//     transform: translateY(150%);
//     transition: all 0.3s ease-in-out;

//     .video-time {
//         display: flex;
//         color: $white-color;
//         align-items: flex-start;
//         justify-content: center;
//         width: auto;
//         font-size: 15px;

//         p,
//         span {
//             margin-top: 10px;
//         }
//     }
// }

// .actions button {
//     background: none;
//     border: none;
//     outline: none;
//     cursor: pointer;
// }

// .actions button i {
//     background-color: none;
//     color: white;
//     font-size: 30px;
// }

// .timeline {
//     -webkit-appearance: none;
//     width: calc(100% - 125px);
//     background-color: rgba(255, 255, 255, 0.3);
//     height: 4px;
//     border-radius: 20px;
//     background: #d3d3d3;
//     outline: none;
//     opacity: 0.7;
//     -webkit-transition: 0.2s;
//     transition: opacity 0.2s;
// }

// .timeline:hover {
//     opacity: 1;
// }

// .timeline::-webkit-slider-thumb {
//     -webkit-appearance: none;
//     appearance: none;
//     width: 15px;
//     height: 15px;
//     border-radius: 50%;
//     background: $primary-color;
//     cursor: pointer;
// }

// .timeline::-moz-range-thumb {
//     width: 15px;
//     height: 15px;
//     border-radius: 50%;
//     background: $primary-color;
//     cursor: pointer;
// }

// // input[type="range"] {
// //     -webkit-appearance: none;
// //     background: rgba(255, 255, 255, 0.2);
// //     border-radius: 20px;
// //     height: 4px;
// //     width: 350px;
// // }

// // input[type="range"]::-webkit-slider-thumb {
// //     -webkit-appearance: none;
// //     cursor: pointer;
// //     height: 6px;
// // }

// // input[type="range"]::-moz-range-progress {
// //     background: white;
// // }

// .velocity {
//     appearance: none;
//     background: none;
//     color: white;
//     outline: none;
//     border: none;
//     text-align: center;
//     font-size: 16px;
// }

// .mute-btn {
//     background: none;
//     border: none;
//     outline: none;
//     cursor: pointer;
// }

// .mute-btn i {
//     background-color: none;
//     color: white;
//     font-size: 20px;
// }

// .container {
//     width: 100%;
//     height: 100%;
//     display: flex;
//     justify-content: center;
//     align-items: center;

//     .video-container {
//         width: 100%;
//         height: 40vh;
//         border-radius: 4px;
//         margin: 0 auto;
//         position: relative;
//         display: flex;
//         flex-direction: column;
//         justify-content: center;

//         video {
//             // width: 400px;
//             height: 65vh;
//             border-radius: 4px;
//             // margin-top: 30%;
//         }

//         .video-controls {
//             right: 0;
//             left: 0;
//             padding: 10px;
//             position: absolute;
//             bottom: -50%;
//             transition: all 0.2s ease;
//             background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.5));
//         }

//         .video-controls.hide {
//             opacity: 0;
//             pointer-events: none;
//         }

//         .video-progress {
//             position: relative;
//             height: 8.4px;
//             margin-bottom: 10px;

//             progress {
//                 -webkit-appearance: none;
//                 -moz-appearance: none;
//                 appearance: none;
//                 border-radius: 2px;
//                 width: 100%;
//                 height: 8.4px;
//                 pointer-events: none;
//                 position: absolute;
//                 top: 0;
//             }

//             progress::-webkit-progress-bar {
//                 background-color: #474545;
//                 border-radius: 2px;
//             }

//             progress::-webkit-progress-value {
//                 background: $primary-color;
//                 border-radius: 2px;
//             }

//             progress::-moz-progress-bar {
//                 border: 1px solid $primary-color;
//                 background: $primary-color;
//             }
//         }

//         .seek {
//             position: absolute;
//             top: 0;
//             width: 100%;
//             cursor: pointer;
//             margin: 0;
//         }

//         .seek:hover + .seek-tooltip {
//             display: block;
//         }

//         .seek-tooltip {
//             display: none;
//             position: absolute;
//             top: -50px;
//             margin-left: -20px;
//             font-size: 12px;
//             padding: 3px;
//             content: attr(data-title);
//             font-weight: bold;
//             color: $white-color;
//             background-color: rgba(0, 0, 0, 0.6);
//         }

//         .bottom-controls {
//             display: flex;
//             justify-content: space-between;
//             align-items: center;
//         }

//         .left-controls {
//             display: flex;
//             align-items: center;
//             color: $white-color;
//         }

//         .volume-controls {
//             display: flex;
//             align-items: center;
//             margin-right: 10px;

//             progress {
//                 -webkit-appearance: none;
//                 -moz-appearance: none;
//                 appearance: none;
//                 border-radius: 2px;
//                 height: 5px;
//                 pointer-events: none;
//                 position: absolute;
//                 margin-left: 41px;
//                 width: 100px;
//             }

//             progress::-webkit-progress-bar {
//                 background-color: #474545;
//                 border-radius: 2px;
//             }

//             progress::-webkit-progress-value {
//                 background: $white-color;
//                 border-radius: 2px;
//             }

//             progress::-moz-progress-bar {
//                 border: 1px solid $white-color;
//                 background: $white-color;
//             }
//         }

//         .volume-controls input {
//             width: 100px;
//             opacity: 1;
//             transition: all 0.4s ease;
//         }

//         .volume-controls:hover input,
//         .volume-controls input:focus {
//             width: 100px;
//             opacity: 1;
//         }

//         button {
//             cursor: pointer;
//             position: relative;
//             margin-right: 7px;
//             font-size: 12px;
//             padding: 3px;
//             border: none;
//             outline: none;
//             background-color: transparent;
//         }

//         button * {
//             pointer-events: none;
//         }

//         button::before {
//             content: attr(data-title);
//             position: absolute;
//             display: none;
//             right: 0;
//             top: -50px;
//             background-color: rgba(0, 0, 0, 0.6);
//             color: $white-color;
//             font-weight: bold;
//             padding: 4px 6px;
//             word-break: keep-all;
//             white-space: pre;
//         }

//         button:hover::before {
//             display: inline-block;
//         }

//         .fullscreen-button {
//             margin-right: 0;
//         }

//         .pip-button svg {
//             width: 26px;
//             height: 26px;
//         }

//         .playback-animation {
//             pointer-events: none;
//             position: absolute;
//             top: 50%;
//             left: 50%;
//             margin-left: -40px;
//             margin-top: -40px;
//             width: 80px;
//             height: 80px;
//             border-radius: 80px;
//             background-color: rgba(0, 0, 0, 0.6);
//             display: flex;
//             justify-content: center;
//             align-items: center;
//             opacity: 0;
//         }

//         input[type="range"] {
//             -webkit-appearance: none;
//             -moz-appearance: none;
//             height: 8.4px;
//             background: transparent;
//             cursor: pointer;
//         }

//         input[type="range"]:focus {
//             outline: none;
//         }

//         input[type="range"]::-webkit-slider-runnable-track {
//             width: 100%;
//             cursor: pointer;
//             border-radius: 1.3px;
//             -webkit-appearance: none;
//             transition: all 0.4s ease;
//         }

//         input[type="range"]::-webkit-slider-thumb {
//             height: 16px;
//             width: 16px;
//             border-radius: 16px;
//             background: $primary-color;
//             cursor: pointer;
//             -webkit-appearance: none;
//             margin-left: -1px;
//         }

//         input[type="range"]:focus::-webkit-slider-runnable-track {
//             background: transparent;
//         }

//         input[type="range"].volume {
//             height: 5px;
//             background-color: transparent;
//         }

//         input[type="range"].volume::-webkit-slider-runnable-track {
//             background-color: transparent;
//             z-index: 0;
//         }

//         input[type="range"].volume::-webkit-slider-thumb {
//             margin-left: 0;
//             height: 14px;
//             width: 14px;
//             background: $white-color;
//         }

//         input[type="range"]::-moz-range-track {
//             width: 100%;
//             height: 8.4px;
//             cursor: pointer;
//             border: 1px solid transparent;
//             background: transparent;
//             border-radius: 1.3px;
//         }

//         input[type="range"]::-moz-range-thumb {
//             height: 14px;
//             width: 14px;
//             border-radius: 50px;
//             border: 1px solid $primary-color;
//             background: $primary-color;
//             cursor: pointer;
//             margin-top: 5px;
//         }

//         input[type="range"]:focus::-moz-range-track {
//             outline: none;
//         }

//         input[type="range"].volume::-moz-range-thumb {
//             border: 1px solid $white-color;
//             background: $white-color;
//         }

//         .hidden {
//             display: none;
//         }

//         svg {
//             width: 28px;
//             height: 28px;
//             fill: $white-color;
//             stroke: $white-color;
//             cursor: pointer;
//         }
//     }
// }

.video-player-container {
    .container,
    .video-controls,
    .video-timer,
    .options {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .hidden {
        display: none;
    }

    svg {
        width: 28px;
        height: 28px;
        fill: $white-color;
        stroke: $white-color;
        cursor: pointer;
    }

    .playback-animation {
        pointer-events: none;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -40px;
        margin-top: -40px;
        width: 80px;
        height: 80px;
        border-radius: 80px;
        background-color: rgba(0, 0, 0, 0.6);
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0;
    }

    .container {
        width: 98%;
        user-select: none;
        overflow: hidden;
        max-width: 900px;
        border-radius: 5px;
        background: $black-color;
        aspect-ratio: 16 / 9;
        position: relative;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
        height: 400px;
    }

    .container.fullscreen {
        max-width: 100%;
        width: 100%;
        height: 100vh;
        border-radius: 0px;
    }

    .wrapper {
        position: absolute;
        left: 0;
        right: 0;
        z-index: 1;
        opacity: 0;
        bottom: -15px;
        transition: all 0.08s ease;
    }

    .container.show-controls .wrapper {
        opacity: 1;
        bottom: 0;
        transition: all 0.13s ease;
    }

    .wrapper::before {
        content: "";
        bottom: 0;
        width: 100%;
        z-index: -1;
        position: absolute;
        height: calc(100% + 35px);
        pointer-events: none;
        background: linear-gradient(to top, rgba(0, 0, 0, 0.7), transparent);
    }

    .video-timeline {
        height: 7px;
        width: 100%;
        cursor: pointer;
    }

    .video-timeline .progress-area {
        height: 3px;
        position: relative;
        background: rgba(255, 255, 255, 0.6);
    }

    .progress-area span {
        position: absolute;
        left: 50%;
        top: -25px;
        font-size: 13px;
        color: $white-color;
        pointer-events: none;
        transform: translateX(-50%);
    }

    .progress-area .progress-bar {
        width: 0%;
        height: 100%;
        position: relative;
        background: $primary-color;
    }

    .progress-bar::before {
        content: "";
        right: 0;
        top: 50%;
        height: 13px;
        width: 13px;
        position: absolute;
        border-radius: 50%;
        background: $primary-color;
        transform: translateY(-50%);
    }

    .progress-bar::before,
    .progress-area span {
        display: none;
    }

    .video-timeline:hover .progress-bar::before,
    .video-timeline:hover .progress-area span {
        display: block;
    }

    .wrapper .video-controls {
        padding: 5px 20px 10px;
    }

    .video-controls .options {
        width: 100%;
    }

    .video-controls .options:first-child {
        justify-content: flex-start;
    }

    .video-controls .options:last-child {
        justify-content: flex-end;
    }

    .options button {
        height: 40px;
        width: 40px;
        font-size: 19px;
        border: none;
        cursor: pointer;
        background: none;
        color: #efefef;
        border-radius: 3px;
        transition: all 0.3s ease;
    }

    .options button :where(i, span) {
        height: 100%;
        width: 100%;
        line-height: 40px;
    }

    .options button:hover :where(i, span) {
        color: $white-color;
    }

    .options button:active :where(i, span) {
        transform: scale(0.9);
    }

    .options button span {
        font-size: 23px;
    }

    .options input {
        height: 4px;
        margin-left: 3px;
        max-width: 75px;
        accent-color: $primary-color;
    }

    .options .video-timer {
        color: #efefef;
        margin-left: 15px;
        font-size: 14px;

        p {
            margin-bottom: 0 !important;
        }
    }

    .video-timer .separator {
        margin: 0 5px;
        font-size: 16px;
        font-family: "Open sans";
    }

    .playback-content {
        display: flex;
        position: relative;
    }

    .playback-content .speed-options {
        position: absolute;
        list-style: none;
        left: -40px;
        bottom: 40px;
        width: 95px;
        overflow: hidden;
        opacity: 0;
        border-radius: 4px;
        pointer-events: none;
        background: rgba(255, 255, 255, 0.9);
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
        transition: opacity 0.13s ease;
        padding-left: 0;
    }

    .playback-content .speed-options.show {
        opacity: 1;
        pointer-events: auto;
    }

    .speed-options li {
        cursor: pointer;
        color: $black-color;
        font-size: 14px;
        margin: 2px 0;
        padding: 5px 0 5px 15px;
        transition: all 0.1s ease;
    }

    .speed-options li:where(:first-child, :last-child) {
        margin: 0px;
    }

    .speed-options li:hover {
        background: #dfdfdf;
    }

    .speed-options li.active {
        color: $white-color;
        background: $primary-color;
    }

    .container video {
        width: 100%;
        height: 100%;
        z-index: 0;
    }

    @media screen and (max-width: 540px) {
        .wrapper .video-controls {
            padding: 3px 10px 7px;
        }

        .options input,
        .progress-area span {
            display: none !important;
        }

        .options button {
            height: 30px;
            width: 30px;
            font-size: 17px;
        }

        .options .video-timer {
            margin-left: 5px;
        }

        .video-timer .separator {
            font-size: 14px;
            margin: 0 2px;
        }

        .options button :where(i, span) {
            line-height: 30px;
        }

        .options button span {
            font-size: 21px;
        }

        .options .video-timer,
        .progress-area span,
        .speed-options li {
            font-size: 12px;
        }

        .playback-content .speed-options {
            width: 75px;
            left: -30px;
            bottom: 30px;
        }

        .speed-options li {
            margin: 1px 0;
            padding: 3px 0 3px 10px;
        }

        .right .pic-in-pic {
            display: none;
        }
    }
}
