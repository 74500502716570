// Remove the default blue and yellow glow
textarea,
select,
input,
button,
a {
    outline: none;
}

// .btn:focus,
textarea:focus,
.form-control:focus,
select:focus,
.custom-select:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {
    border-color: transparent;
    outline: 0;
    outline: thin dotted \9;
    /* IE6-9 */

    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

// Remove the X from Internet Explorer and Chrome input type search
input[type="search"]::-ms-clear {
    display: none;
    width: 0;
    height: 0;
}

input[type="search"]::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
}

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    display: none;
}

// Dropdown Arrow
.dropdown-toggle {
    &::after {
        content: "\f107";
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        display: inline-block;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        line-height: 1;
        border: none;
        vertical-align: inherit;
        font-size: 13px;
        margin-left: 0.5em;
        color: $label-color;
    }
}

// Dropdown Up Arrow
.dropdown-toggle-up {
    &::after {
        content: "\f106";
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        display: inline-block;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        line-height: 1;
        border: none;
        vertical-align: inherit;
        font-size: 13px;
        margin-left: 0.5em;
        color: $label-color;
    }
}

// Dropdown Menu
.dropdown-menu {
    padding: 0;
    border-color: tint($placeholder-color, 60);

    .dropdown-item {
        &:focus,
        &:hover {
            background-color: tint($placeholder-color, 90);
        }

        &:active {
            background: $primary-color !important;
            color: $white-color !important;
        }

        &:first-child {
            border-radius: 4px 4px 0 0;
        }

        &:last-child {
            border-radius: 0 0 4px 4px;
        }

        cursor: pointer;
        padding: 6px 10px;
        font-size: 14px;
        color: $label-color !important;
    }
}

// Reset placeholder styles
.form-control {
    @include placeholder {
        color: $placeholder-color;
    }
}
