.form-group {
    label {
        font-size: 14px;
        color: $label-color;
    }
    .form-control {
        font-size: 14px;
    }
    .error-msg {
        font-size: 12px;
        color: $invalid-color;
        margin-top: 2px;
    }

    .form-control:focus,
    input[type="search"]:focus {
        border-color: $primary-color;
    }
}

.form-group--placeholder-label {
    position: relative;
    padding-top: 2rem;

    label {
        transition: all 0.125s ease;
        position: absolute;
        top: calc(2.5rem - 1px);
        left: calc(0.75rem + 1px);
        margin: 0;
        font-weight: 400;
        font-size: 15px;
        color: rgba(24, 24, 24, 0.46);
        padding: 0 5px;
    }

    .form-control {
        border: 1px solid tint($placeholder-color, 60);
        box-sizing: border-box;
        border-radius: 12px;

        &:focus {
            border: 1px solid $border-color !important;
        }
        &.is-invalid {
            &:focus {
                border-color: $invalid-color !important;
                box-shadow: none;
                background: unset;
            }
            background: unset;
        }
        &.is-valid {
            &:focus {
                border-color: $valid-color !important;
                box-shadow: none;
            }
        }
        &.ng-touched.ng-invalid,
        &.ng-touched.ng-invalid:focus {
            border-color: $invalid-color !important;
        }
    }
    &--active {
        label {
            top: 0;
            left: 0;
            color: #000;
        }
    }
}

.form-content {
    display: flex;
    label {
        font-size: 14px;
        color: $label-color;
        flex: 1;
    }
    .value {
        flex: 1;
        font-size: 14px;
        font-weight: 500;
    }
}

.form-check,
.custom-checkbox {
    label {
        font-size: 14px;
        color: $label-color;
    }
}

.invalid-message {
    p {
        border: 1px solid $invalid-color;
        color: $invalid-color;
        border-radius: 0.25rem;
        padding: 2px 6px;
        text-align: center;
        font-size: 14px;
        text-transform: capitalize;
    }
}

.valid-message {
    p {
        border: 1px solid $valid-color;
        color: $valid-color;
        border-radius: 0.25rem;
        padding: 2px 6px;
        text-align: center;
        font-size: 14px;
    }
}
.authentication-container {
    img {
        margin-top: 50px;
    }
}
.authenticate-bg {
    background: $white-color;
    height: 100vh;
}
.small-description {
    color: $light-black !important;
    font-size: 15px;
    text-align: center !important;

    a {
        text-decoration: underline !important;
        color: $light-black;
        cursor: pointer;
    }
}

.diabled-class {
    pointer-events: none;
    opacity: 0.5;
}

.box {
    .social {
        float: none;
        margin: 0 auto 30px;
        text-align: center;
        cursor: pointer;

        .facebook {
            background-color: #455ca8 !important;
            color: #ffffff;
            padding: 15px 22px !important;

            &:hover {
                background-color: #455ca8 !important;
                color: #ffffff;
            }
        }

        .google {
            background-color: #f74933 !important;
            color: #ffffff !important;
        }

        .apple {
            background-color: #000 !important;
            color: #ffffff !important;
        }

        .twitter {
            background-color: #00acee !important;
            color: #ffffff !important;
        }

        .circle {
            background-color: #eeeeee;
            color: #ffffff;
            border-radius: 100px;
            display: inline-block;
            margin: 0 17px;
            padding: 15px 20px;

            .fa {
                font-size: 16px;
            }
        }
    }
}

.division {
    float: none;
    margin: 0 auto 18px;
    overflow: hidden;
    position: relative;
    text-align: center;
    width: 100%;

    span {
        color: $light-black;
        font-size: 18px;
        font-weight: 500;
    }

    .line {
        border-top: 1px solid $border-color;
        position: absolute;
        top: 14px;
        width: 42%;
    }
    .line.l {
        left: 0;
    }
    .line.r {
        right: 0;
    }
}
