.tab-module {
    display: flex;
    justify-content: center;
    align-items: center;
    ul {
        padding-left: 0px;
        margin-bottom: 0;
        list-style: none;
        li {
            text-align: center;
            color: $heading-color;
            font-size: 16px;
            font-weight: 600;
            letter-spacing: 3px;
            text-transform: uppercase;
            padding: 12px 20px 18px 20px;
            display: inline-block;
            background: transparent;
            border-radius: 4px 4px 0 0;
            margin-right: 6px;
            outline: none;
            cursor: pointer;
            margin-top: 10px;
            border-top: 1px solid tint($placeholder-color, 60);
            border-left: 1px solid tint($placeholder-color, 60);
            border-right: 1px solid tint($placeholder-color, 60);
            &.active {
                background: $white-color;
                position: relative;
                &:before {
                    content: "";
                    height: 1px;
                    width: 100%;
                    background: $white-color;
                    position: absolute;
                    bottom: -1px;
                    left: 0;
                }
            }
        }
    }
}

.tabbable-panel {
    border: 1px solid #eee;
    padding: 10px;
}
.tabbable-line {
    & > .nav-tabs {
        border: none;
        margin: 0px;
        & > li {
            margin-right: 2px;
            & > a {
                border: 0;
                margin-right: 0;
                color: #737373;
                & > i {
                    color: #a6a6a6;
                }
            }
            &.active {
                border-bottom: 4px solid #32465b;
                position: relative;
                & > a {
                    border: 0;
                    color: #333333;
                    & > i {
                        color: #404040;
                    }
                }
            }
        }
    }
    & > .tab-content {
        margin-top: -3px;
        background-color: #fff;
        border: 0;
        border-top: 1px solid #eee;
        padding: 15px 0;
    }
}
.tabbable-line > .nav-tabs > li.open,
.tabbable-line > .nav-tabs > li:hover {
    border-bottom: 4px solid rgb(80, 144, 247);
}
.tabbable-line > .nav-tabs > li.open > a,
.tabbable-line > .nav-tabs > li:hover > a {
    border: 0;
    background: none !important;
    color: #333333;
}
.tabbable-line > .nav-tabs > li.open > a > i,
.tabbable-line > .nav-tabs > li:hover > a > i {
    color: #a6a6a6;
}
.tabbable-line > .nav-tabs > li.open .dropdown-menu,
.tabbable-line > .nav-tabs > li:hover .dropdown-menu {
    margin-top: 0px;
}
.portlet {
    .tabbable-line {
        & > .tab-content {
            padding-bottom: 0;
        }
    }
}
