.accordion-wrapper {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid shade($background-color, 10);
    // border: 1px solid #f2f3f8;
    // box-shadow: 0px 1px 4px -1px #f2f3f8;
    // margin-bottom: 4px;
    .accordion-header {
        padding: 0.625rem;
        // cursor: pointer;
        display: flex;
        // &:hover {
        //   background-color: tint($background-color, 40);
        // }
        &:hover {
            background-color: tint($background-color, 60);
        }
    }
    .accordion-body {
        // display: none;
        display: block;
        margin-left: 60px;
    }
    &.accordion-expand {
        .accordion-header {
            cursor: unset;
            &:hover {
                background-color: transparent;
            }
            .toggle .fa-chevron-down {
                @include transform(rotate(180deg));
            }
        }
        .accordion-body {
            display: block;
        }
    }
}
