.sidebar {
    height: 100%;
    width: 5%;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: $white-color;
    overflow-x: hidden;
    transition: 0.5s;
    // padding-top: 60px;
    white-space: nowrap;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .admin-panel-text {
        margin-top: -25px;
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .admin-text {
            font-weight: 500;
            font-size: 17px;
        }

        .sub-text {
            color: $secondary-text-color;
            margin-top: -15px;
            margin-left: 15px;
            font-weight: 300;
            font-size: 15px;
        }

        img {
            height: 16px;
            width: 16px;
            margin: 2px 10px;
        }
    }
    a {
        padding: 10px 20px;
        text-decoration: none;
        display: flex;
        transition: 0.3s;
        font-size: 15px;
        cursor: pointer;

        .expand-div {
            border-radius: 50px;
            padding-right: 55px;
            max-width: 170px;
            &:hover {
                background: $background-color;
            }
        }

        .active {
            background: $background;
            &:hover {
                background: $background;
            }
        }

        .category-icon {
            border-radius: 50px;
            padding: 15px 18px;

            img {
                width: 18px;
            }
        }

        .logout-icon {
            padding: 12px !important;
            color: $primary-text-color;
        }
    }
    .closebtn {
        position: absolute;
        top: 0;
        right: 25px;
        font-size: 36px;
        margin-left: 50px;
        color: $primary-color;
    }
}
.expand-text {
    // padding: 0;
    // display: flex !important;
    visibility: visible !important;
}
.icon-text {
    vertical-align: middle;
    // padding-left: 25px;
    text-transform: capitalize;
    color: $primary-text-color;
    font-weight: 600;
    visibility: hidden;
    text-align: center;
    font-size: 15px;
    font-weight: 600;
}

.content-container {
    transition: margin-left 0.5s;
    padding: 16px !important;
    margin-left: 5%;
}
