.header {
    // .navbar-nav .nav-item.active>a,
    // .navbar-nav .nav-item:hover>a {
    //     color: $primary-color;
    // }

    .navbar {
        background: $white-color;
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.08);
        position: fixed;
        z-index: 99;
        width: 100%;

        .navbar-brand {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px;

            .brand-name {
                color: $light-black;
                text-transform: lowercase;
                margin: 0;
                font-weight: bold;
                padding: 5px 15px;
                font-size: 25px;
            }

            img {
                max-height: 50px;
            }
        }

        .navbar-nav {
            .nav-item {
                padding: 0 20px;
                -webkit-transition: all 0.3s ease-out 0s;
                -moz-transition: all 0.3s ease-out 0s;
                -ms-transition: all 0.3s ease-out 0s;
                -o-transition: all 0.3s ease-out 0s;
                transition: all 0.3s ease-out 0s;
                cursor: pointer;
                color: $light-black;

                a.active,
                a:hover {
                    color: $primary-color;
                }

                .nav-link {
                    color: $light-black;
                    font-weight: 600;
                    font-size: 15px;
                    letter-spacing: 0.2px;
                }
                .page-scroll {
                    color: $light-black;
                }
            }
        }

        .navbar-toggler {
            padding: 3px;

            .toggler-icon {
                width: 30px;
                height: 2px;
                background-color: rgba(0, 0, 0, 0.1);
                display: block;
                margin: 5px 0;
                position: relative;
                -webkit-transition: all 0.3s ease-out 0s;
                -moz-transition: all 0.3s ease-out 0s;
                -ms-transition: all 0.3s ease-out 0s;
                -o-transition: all 0.3s ease-out 0s;
                transition: all 0.3s ease-out 0s;
            }
        }
        .navbar-toggler.active .toggler-icon:nth-of-type(1) {
            -webkit-transform: rotate(45deg);
            -moz-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            -o-transform: rotate(45deg);
            transform: rotate(45deg);
            top: 7px;
        }

        .navbar-toggler.active .toggler-icon:nth-of-type(2) {
            opacity: 0;
        }

        .navbar-toggler.active .toggler-icon:nth-of-type(3) {
            -webkit-transform: rotate(135deg);
            -moz-transform: rotate(135deg);
            -ms-transform: rotate(135deg);
            -o-transform: rotate(135deg);
            transform: rotate(135deg);
            top: -7px;
        }
    }
}
