// .modal {
//     /* modal container fixed across whole screen */
//     position: fixed;
//     top: 0;
//     right: 0;
//     bottom: 0;
//     left: 0;

//     /* z-index must be higher than .modal-background */
//     z-index: 1000;

//     /* enables scrolling for tall modals */
//     overflow: auto;
// }

.modal-background {
    /* modal background fixed across whole screen */
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    /* semi-transparent black  */
    background-color: #000;
    opacity: 0.75;

    /* z-index must be below .modal and above everything else  */
    z-index: 900;
}

body.modal-open {
    /* body overflow is hidden to hide main scrollbar when modal window is open */
    overflow: hidden;
}
.modal {
    top: -15% !important;
}
.modal-dialog {
    padding: 15px;

    .modal-content {
        padding: 10px;

        .modal-header {
            .media-close {
                // margin: -1rem 11rem -1rem auto;
            }
        }

        textarea,
        input,
        select {
            &:focus {
                border: 1px solid #ced4da;
            }
        }

        .modal-title {
            h1 {
                float: left;
                margin-bottom: 0;
                font-size: 16px;
                color: $black-color;
            }
            i {
                float: right;
                cursor: pointer;
                &:hover {
                    transition: all 0.3s linear;
                    opacity: 0.8;
                }
            }
        }
        .modal-body {
            padding: 10px;
            // background: #fff;
            // max-width: 600px;
            border-radius: 4px;
            /* margin exposes part of the modal background */
            // margin: 0 30px;
            top: 50%;
            position: relative;
            // transform: translate(0, -50%);

            .alert {
                font-size: 15px;
                padding: 0;
            }

            .content-body {
                margin-top: 30px;
                margin-bottom: 10px;
                .content-results {
                    border-top: 1px solid tint($placeholder-color, 70);
                    padding-top: 20px;
                    margin-top: 20px;
                    .form-group {
                        margin-bottom: 20px;
                        .form-control {
                            font-weight: 500;
                        }
                    }
                }
                .clear {
                    cursor: pointer;
                    color: tint($red-color, 30);
                    font-size: 12px;
                    i {
                        font-size: 12px;
                    }
                    &:hover {
                        transition: all 0.3s linear;
                        color: tint($red-color, 50);
                    }
                }
                .go-group {
                    position: relative;
                    input {
                        padding-right: 100px;
                    }
                    .btn {
                        position: absolute;
                        right: 4px;
                        top: 4px;
                        padding: 2px 20px;
                    }
                }
            }
        }
    }
}
