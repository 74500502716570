@keyframes rotating {
    from {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes pulse {
    0% {
        @include transform(scale(0.95));
        -moz-box-shadow: 0 0 0 0 rgba(tint($blue, 20), 0.6);
        box-shadow: 0 0 0 0 rgba(tint($blue, 20), 0.6);
    }
    80% {
        transform: scale(1);
        @include transform(scale(1.2));
        -moz-box-shadow: 0 0 0 10px rgba(tint($blue, 20), 0);
        box-shadow: 0 0 0 10px rgba(tint($blue, 20), 0);
    }
    100% {
        @include transform(scale(0.95));
        -moz-box-shadow: 0 0 0 0 rgba(tint($blue, 20), 0);
        box-shadow: 0 0 0 0 rgba(tint($blue, 20), 0);
    }
}

@keyframes progress {
    0% {
        background-position: -200px 0;
    }
    100% {
        background-position: calc(200px + 100%) 0;
    }
}

.rotating {
    -webkit-animation: rotating 2s linear infinite;
    -moz-animation: rotating 2s linear infinite;
    -ms-animation: rotating 2s linear infinite;
    -o-animation: rotating 2s linear infinite;
    animation: rotating 2s linear infinite;
}

.pulse {
    &:before {
        @include animation(pulse, 1.2s, infinite);
    }
}

.scale-on-hover {
    transform: scale(0.93);
    transition: all 0.2s ease;
    &:hover {
        transform: scale(1);
    }
}
