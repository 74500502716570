.skeleton-loader-container {
    margin: 1rem auto;
    ul {
        list-style-type: none;
        padding-left: 0px;
        li {
            display: flex;
            align-items: flex-start;
            margin-bottom: 0.5rem;
            padding: 10px;
            .circle {
                border-radius: 50%;
                margin-right: 1rem;
                width: 34px;
                height: 34px;
                overflow: hidden;
            }
            .loader {
                position: relative;
                box-shadow: 0px 1px 1px 0px rgb(242, 243, 248);
                &:before {
                    content: "";
                    position: absolute;
                    background: 0 0/100px 100% no-repeat $background-color;
                    background-image: linear-gradient(
                        90deg,
                        rgba(255, 255, 255, 0),
                        rgba(255, 255, 255, 0.8),
                        rgba(255, 255, 255, 0)
                    );
                    @include animation(progress, 1.2s, infinite);
                    width: 100%;
                    height: 100%;
                }
            }
            .info-wrapper {
                flex: 1;
                div {
                    border-radius: 2px;
                    overflow: hidden;
                }
                div:not(:last-child) {
                    margin-bottom: 10px;
                }
            }
            .large {
                width: 80%;
                height: 0.5rem;
            }
            .medium {
                width: 50%;
                height: 0.3rem;
            }
        }
    }
}
.skeleton-loader-background {
    width: 100%;
    height: 15px;
    display: block;
    background: lightgray;
}

.skeleton-loader-gradient {
    width: 100%;
    height: 100%;
    display: block;
    background: linear-gradient(
            to right,
            rgba(255, 255, 255, 0),
            rgba(255, 255, 255, 0.5) 50%,
            rgba(255, 255, 255, 0) 80%
        ),
        lightgray;
    background-repeat: repeat-y;
    background-size: 50px 200px;
    background-position: 0 0;
}

.skeleton-loader,
.skeleton-loader:empty {
    width: 100%;
    height: 100%;
    display: block;
    background: linear-gradient(
            to right,
            rgba(255, 255, 255, 0),
            rgba(255, 255, 255, 0.5) 50%,
            rgba(255, 255, 255, 0) 80%
        ),
        lightgray;
    background-repeat: repeat-y;
    background-size: 50px 500px;
    background-position: 0 0;
    animation: shine 1s infinite;
}

@keyframes shine {
    to {
        background-position: 100% 0;
    }
}

.skeleton-box {
    display: inline-block;
    position: relative;
    overflow: hidden;
    background-color: #dddbdd;

    &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transform: translateX(-100%);
        background-image: linear-gradient(
            90deg,
            rgba(#fff, 0) 0,
            rgba(#fff, 0.2) 20%,
            rgba(#fff, 0.5) 60%,
            rgba(#fff, 0)
        );
        animation: shimmer 1s infinite;
        content: "";
    }

    @keyframes shimmer {
        100% {
            transform: translateX(100%);
        }
    }
}
